<template>
  <Modal
      v-model="listDialog"
      modalTitle="Pessoas"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="1080px"
      style="max-height: 70%"
      @input="close"
    >
      <v-container fluid pa-0 style="overflow: hidden">
        <v-layout pa-0>
          <v-flex sm12 style="height: 82vh">
            <div class="content-text-block font-sys" style="height: 100%; padding: 0">
              <body class="d-flex flex-column" style="background-color: white">
                <div style="width: 100%; height: 90%; overflow-y: scroll; padding: 20px">
                  <div style="display: flex; justify-content: space-between;"
                  class="mb-3">
                    <div>
                      <v-checkbox label="Mostrar inativos"
                      v-model="seeDisabled"
                      @change="makeSearch"></v-checkbox>
                    </div>
                    <v-btn
                      style="color: white"
                      class="btn-primary mb-2"
                      @click="add"
                    >Adicionar
                    </v-btn>
                  </div>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    label="Pesquisar"
                    single-line
                    hide-details
                    class="mb-3"
                    @input="makeSearch"
                  ></v-text-field>
                  <table class="v-datatable v-table theme--light">
                      <thead>
                          <tr>
                              <th class="text-sm-left">ID</th>
                              <th class="text-sm-left">Nome</th>
                              <th class="text-sm-left">CPF / CNPJ</th>
                              <th class="text-sm-left">Opções</th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr v-for="person in personsList" :key="person.id">
                              <td>{{ person.id }}</td>
                              <td>{{person.donator}}</td>
                              <td>{{ person.cpf ? formatter.formatCpf(person.cpf) : formatter.formatCnpj(person.cnpj) }}</td>
                              <td>
                                <v-btn
                                  @click="edit(person)"
                                  >Editar
                                </v-btn>
                                <v-btn
                                  @click="disable(person)"
                                  style="background-color: #ff0000b5; color: white;"
                                  v-if="person.active"
                                  >
                                  Desativar
                                </v-btn>
                                <v-btn
                                    @click="enable(person)"
                                    style="color: white"
                                    class="btn-primary mb-2"
                                    v-if="!person.active"
                                  >
                                  Ativar
                                </v-btn>
                              </td>
                          </tr>
                      </tbody>
                  </table>
                </div>
                <v-flex style="padding: 5px">
                  <v-btn
                    @click="close"
                    >Cancelar
                  </v-btn>
                </v-flex>
              </body>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
</template>

<script type="plain/text">
// Components
import Modal from "@/components/Modal.vue";

// Services
import DocService from "../../../../scripts/services/doc.service";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";

// Vuex
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Modal
  },
  props: {
    list: Object,
  },
  mounted() {
    this.reloadPersonsList();
  },
  data() {
    return {
      docService: new DocService(),
      formatter: new FormatterHelper(),
      headers: [
          {
            text: 'Id',
            sortable: false,
            value: 'id',
          },
          { text: 'Nome', value: 'name' },
          { text: 'CPF / CNPJ', value: 'cpf || cnpj' },
          { text: 'Opções', value: 'options' },
        ],
      search: null,
      seeDisabled: false,
    }
  },
  computed: {
    ...mapGetters([
      'listDialog',
      'personsList',
      'personsListBackup'
    ])
  },
  methods: {
    ...mapActions([
      'setIsEdit',
      'clearAllPerson',
      'openPersonDialog',
      'closeListDialog',
      'reloadPersonsList',
      'setPersonsList',
      'setPersonToEdit',
      'setNewPersonFormKey'
    ]),
    add() {
      this.setIsEdit(false)
      this.clearAllPerson()
      this.setNewPersonFormKey(); // update person form
      this.openPersonDialog()
      this.close()
    },
    edit(person) {
      this.setIsEdit(true)
      this.setPersonToEdit(person);
      this.openPersonDialog()
      this.closeListDialog();
    },
    close() {
      this.seeDisabled = false;
      this.search = ''
      this.closeListDialog()
    },
    disable(person) {
      person.active = 0;
      this.docService.savePerson(person)
    },
    enable(person) {
      person.active = 1;
      this.docService.savePerson(person)
    },
    makeSearch() {
      this.setPersonsList(this.personsListBackup);

      if (!this.seeDisabled)
        this.setPersonsList(this.personsListBackup.filter((p) => p.active))

      if (this.search !== null || this.search !== '') {
        this.setPersonsList(this.personsList.filter((p) => {
            return (p.id).toString().includes(this.search) ||
            (p.donator).toLowerCase().includes(this.search.toLowerCase()) ||
            (p.cpf ? (this.formatter.formatCpf(p.cpf)).includes(this.search) : (this.formatter.formatCnpj(p.cnpj)).includes(this.search))
        }))
      }
    },
  },
}

</script>