<template>
  <Modal
      v-model="personDialog"
      :modalTitle="!isPersonConfirm ? 'Cadastro de Pessoa' : 'Confirmar dados de cadastro'"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      style="max-height: 70%"
      @input="close"
      :key="newPersonFormKey"
    >
      <v-container fluid pa-0 style="overflow: hidden">
        <v-layout pa-0>
          <v-flex sm12 style="height: 82vh">
            <div class="content-text-block font-sys" style="height: 100%; padding: 0">
              <body class="d-flex flex-column" style="background-color: white">
                <div style="width: 100%; height: 90%; overflow-y: scroll; padding: 20px"
                >
                  <InputText
                    ref="cpfCnpjInput"
                    type="text"
                    textLabel="CPF / CNPJ"
                    placeHolder="CPF / CNPJ"
                    :value="person.document"
                    v-model="person.document"
                    :required="true"
                    invalidInputMessage="Preencha o CPF/CNPJ válido"
                    :validation="validateCPFCNPJ"
                    :mask="['###.###.###-##', '##.###.###/####-##']"
                  />
                  <InputText
                    ref="donatorInput"
                    type="text"
                    textLabel="Nome Completo/Razão social"
                    placeHolder="Nome Completo/Razão social"
                    v-model="person.donator"
                    :required="true"
                    invalidInputMessage="Preencha o Nome/Razão social"
                    :labelCharLimit="100"
                  />
                  <InputSelect
                    ref="companyTypeInput"
                    textLabel="Empresa"
                    :items="['Pública', 'Privada', 'N/A']"                    
                    v-model="person.companyType"
                    :required="true"
                    invalidInputMessage="Preencha o tipo de empresa"
                  ></InputSelect>
                  <InputSelect
                    textLabel="Faz parte de algum grupo empresarial?"
                    :items="[
                      { text: 'Sim', value: 'true' },
                      { text: 'Não', value: 'false' },
                      { text: 'N/A', value: ''}
                    ]"
                    v-model="person.businessGroup"
                    valueAttribute="value"
                    textAttribute="text"
                  ></InputSelect>
                  <InputText
                    v-if="person.businessGroup === 'true'"
                    ref="groupInput"
                    type="text"
                    textLabel="Nome do Grupo empresarial"
                    placeHolder="Nome do Grupo empresarial"
                    v-model="person.businessGroupName"
                    :required="true"
                    invalidInputMessage="Preencha o nome do Grupo empresarial"
                  />
                  <InputText
                    type="text"
                    textLabel="Nome do dirigente máximo da empresa incentivadora"
                    placeHolder="Nome do dirigente máximo da empresa incentivadora"
                    v-model="person.manager"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="100"
                  />
                  <InputText
                    ref="zipInput"
                    type="text"
                    textLabel="CEP"
                    mask="#####-###"
                    placeHolder="00000-000"
                    autocomplete="billing postal-code"
                    v-model="person.zip"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :required="true"
                    :validation="validateCEP"
                    @blur="searchCEP()"
                  />
                  <InputText
                    ref="streetInput"
                    type="text"
                    textLabel="Rua"
                    placeHolder="Rua"
                    v-model="person.street"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="100"
                  />
                  <InputText
                    ref="numberInput"
                    type="text"
                    textLabel="Número"
                    placeHolder="Número"
                    v-model="person.number"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="12"
                    :mask="['############']"
                  />
                  <InputText
                    ref="neighborhoodInput"
                    type="text"
                    textLabel="Bairro"
                    placeHolder="Bairro"
                    v-model="person.neighborhood"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="50"
                  />
                  <InputText
                    type="text"
                    textLabel="Complemento"
                    placeHolder="Complemento"
                    v-model="person.complement"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="50"
                  />
                  <InputText
                    ref="cityInput"
                    type="text"
                    textLabel="Cidade"
                    placeHolder="Cidade"
                    v-model="person.city"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="50"
                  />
                  <InputText
                    ref="ufInput"
                    type="text"
                    textLabel="UF"
                    placeHolder="UF"
                    v-model="person.uf"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :labelCharLimit="50"
                  />
                  <InputText
                    type="text"
                    textLabel="Telefone"
                    placeHolder="(00) 00000-0000"
                    :mask="['(##) ####-####', '(##) #####-####']"
                    v-model="person.phone"
                    invalidInputMessae="Por favor, preencha esse campo"
                  />
                </div>
                <v-flex style="padding: 5px">
                  <v-btn
                    @click="close"
                    >Cancelar
                  </v-btn>
                  <v-btn
                    style="color: white"
                    class="btn-primary"
                    v-if="!isPersonConfirm"
                    @click="save"
                    > Salvar
                  </v-btn>
                  <v-btn
                    style="color: white"
                    class="btn-primary"
                    v-if="isPersonConfirm"
                    @click="save"
                    > Confirmar
                  </v-btn>
                </v-flex>
              </body>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
</template>

<script type="plain/text">
// Components
import Modal from "@/components/Modal.vue";
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";

// Services
import DocService from "../../../../scripts/services/doc.service";
import LocationService from "@/scripts/services/location.service.js";

// Helpers
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "@/scripts/helpers/common.helper";

// Vuex
import { mapActions, mapGetters } from "vuex";

export default {
  extends: ValidatableComponent,
  components: {
    Modal
  },
  watch: {
    isAnEdit(val) {
      if (val === false && this.person.document) {
        const document = this.person.document;
        this.clearPerson();
        this.setNewPersonFormKey();
        this.person.document = document;
      }
    }
  },
  computed: {
    ...mapGetters([
      'personDialog',
      'isPersonConfirm',
      'isAnEdit',
      'personToEdit',
      'person',
      'personsListBackup',
      'isFromReceipt',
      'personsListBackup',
      'newPersonFormKey'
    ]),
    documentType()  {
      if (this.person.document) {
        const docLength = ((this.person.document).replaceAll('.','').replaceAll('-','').replaceAll('/','')).length
        if (docLength <= 11) return 'cpf'
        else return 'cnpj'
      }
      return 'cpf'
    },
  },
  data() {
    return {
      docService: new DocService(),
      formatter: new FormatterHelper(),
      locationService: new LocationService(),
      personToCompare: null,
      formKey: 0,
    }
  },
  methods: {
    ...mapActions([
      'setIsPersonConfirm',
      'setIsEdit',
      'closePersonDialog',
      'setPersonToEdit',
      'clearPerson',
      'loadPersonsCombobox',
      'updateSelectedPerson',
      'setNewPersonFormKey'
    ]),
    async save() {
      let hasRegister = false;
      this.personsListBackup.forEach((p) => {
        if (((p.cpf === this.person.document) || (p.cnpj === this.person.document)) && !this.isAnEdit) {
          hasRegister = true
          return;
        }
      })

      if (this.valid() && !hasRegister) {
        if (this.documentType === 'cpf') {
          this.person.cpf = this.person.document.replaceAll('.','').replaceAll('-','').replaceAll('/','')
          this.person.cnpj = null
        } else {
          this.person.cnpj = this.person.document.replaceAll('.','').replaceAll('-','').replaceAll('/','')
          this.person.cpf = null
        }
        this.person.active = true
        this.person.institutionId = this.$route.query.institutionId
        this.updateSelectedPerson();
        await this.docService.savePerson(this.person)
        
        this.isAnEdit ? this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "Cadastro confirmado com sucesso!") : this.$store.commit("SET_SNACKBAR_SUCCESS_MESSAGE", "Pessoa cadastrada com sucesso!");
        const personCopy = {...this.person}
        this.loadPersonsCombobox(personCopy);
        this.close();
        if (this.isPersonConfirm) this.setReceipt(this.person);

        return;
      }

      if (hasRegister) {
        this.$store.commit("SET_SNACKBAR_INFO_MESSAGE", "Este CPF / CNPJ ja está cadastrado!")
        return;
      }
    },
    valid() {
      return this.isValid()      
    },
    searchCEP() {
      if (this.person.zip.length == 8) {
        this.$store.commit("SET_LOADER", true);
        this.locationService
          .searchCEP(this.person.zip)
          .then(obj => {
            if (obj && !obj.erro) {
              this.person.street = obj.street;
              this.person.city = obj.city;
              this.person.uf = obj.state;
              this.person.neighborhood = obj.neighborhood
            } else {
              this.person.street = "";
              this.person.city = "";
              this.person.uf = "";
            }
          this.$store.commit("SET_LOADER", false);
          }
          )
          .catch((error) => {
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    validateCEP() {
      if(this.person.zip.length === 8) return true;
      return false;
    },
    validateCPFCNPJ() {
      if(CommonHelper.validateCNPJ(this.person.document) || CommonHelper.validateCPF(this.person.document))
        return true
      return false
    },
    close() {
      this.closePersonDialog();
      this.updateSelectedPerson();
      this.loadPersonsCombobox();
      this.formKey += 1
    },
  },
}

</script>