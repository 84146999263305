<template>
  <div>
    <HeroInner descrition="Gestão de Instituição" class="mt-70" />

    <div class="grid-box">
      <v-layout bg-white ma-4 mt-5 column br-4 elevation-base>
        <div class="my-donations-header">
          <div>
            <Icon
              name="fal fa-hands title-icon"
              size="32"
              colorFont="#C3C6D5"
            />
            <h2>Recibos Manuais</h2>
          </div>
          <div>
            <v-btn blue @click="newPerson">Cadastrar / Editar</v-btn>
          </div>
          <div>
            <v-btn blue @click="dialog = true" :disabled="projects.length == 0">Novo Recibo Manual</v-btn>
          </div>
          <div>
            <v-btn @click="$refs.confirmDate.open()" :disabled="projects.length == 0">Emitir Recibo</v-btn>
          </div>
          <div>
            <v-btn @click="certisignRequest" :disabled="projects.length == 0">Assinar</v-btn>
          </div>
          <div>
            <v-btn 
              @click="createExcelReport(receipts)" 
              :disabled="projects.length == 0" 
              style="background-color: #36bbac; color: white">Exportar</v-btn>
          </div>
          <div>
            <InputText
              type="search"
              :showLoader="false"
              placeHolder="Buscar..."
              v-model="search"
            />
          </div>
        </div>
        <v-layout bg-white ma-4 mt-2 column br-4>
          <h3 style="margin-bottom: 10px;">Filtros:</h3>
          <div style="display: flex; flex-direction: row; align-items: center;">
            <InputSelect
              textLabel="Status"
              :items="['Pronto para emitir', 'Pronto para assinar', 'Cancelado', 'Assinado', 'Todos']"
              style="margin-left: 8px"
              v-model="itemFilterStatus"
              @input="filterItems('status', itemFilterStatus)"
            ></InputSelect>

            <InputSelect
              textLabel="Projeto"
              :items="projectsList"
              style="margin-left: 8px;"
              v-model="itemFilterProject"
              @input="filterItems('project', itemFilterProject)"
            ></InputSelect>
            
            <InputSelect
              textLabel="Tipo de Pessoa"
              :items="['Pessoa Física', 'Pessoa Jurídica', 'Todos']"
              style="margin-left: 8px"
              v-model="itemFilterPerson"
              @input="filterItems('person', itemFilterPerson)"
            ></InputSelect>
            <div style="display: flex; flex-direction: row; align-items: center;">
              <div>
                <InputText
                  type="text"
                  textLabel="Data Inicial"
                  placeHolder="Data Inicial - Ex: 01/01/2022"
                  :mask="['##/##/####']"
                  width="180px"
                  id="initialDate"
                  v-model="initialDate"
                  invalidInputMessae="Por favor, preencha esse campo"
                  style="margin-left: 10px;"
                  name="initialDate"
                />
              </div>
              
              <InputText
                type="text"
                textLabel="Data Final"
                placeHolder="Data Final - ex: 01/12/2022"
                :mask="['##/##/####']"
                width="180px"
                v-model="finalDate"
                id="finalDate"
                invalidInputMessae="Por favor, preencha esse campo"
                style="margin-left: 10px;"
                name="fianlDate"
              />
            </div>
            <div style="display: flex; flex-direction: row;">
              <v-btn 
                @click="filterItems('date', null)" 
                style="background-color: #36bbac; color: white">
              <i class="fal fa-search" style="margin-right: 5px"></i>Filtrar por Data</v-btn>
              <v-btn 
                @click="clearDate"
                style="background-color: #ff0000b5; color: white; margin-bottom: 5px;">
                <i class="fal fa-trash" style="margin-right: 5px"></i>Limpar Data</v-btn>
            </div>
          </div>
        </v-layout>
        <div>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="receipts"
            :pagination.sync="pagination"
            :search="search"
            :rows-per-page-items="[
              25,
              50,
              100,
              { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
            ]"
            select-all
            item-key="name"
        
          >
            <template v-slot:headers="props">
              <tr>
                <th
                  v-for="header in props.headers"
                  :key="header.text"
                  :class="[
                    'column sortable',
                    pagination.descending ? 'desc' : 'asc',
                    header.value === pagination.sortBy ? 'active' : '',
                  ]"
                  @click="changeSort(header.value)"
                >
                {{ header.text }}
                <v-icon small>arrow_upward</v-icon>
                </th>
                <th></th>
              </tr>
            </template>
            <template v-slot:items="props">
              <tr @click="props.selected = !props.selected" :style="getStyleStatus(props.item)">
                <td>{{ props.item.receiptSequenceId }}</td>
                <td>{{ props.item.donator }}</td>
                <td>{{ getFormatCPForCNPJ(props.item.document) }}</td>
                <td>{{ getPessoaFisicaJuridica(props.item.document) }}</td>
                <td>{{ formatter.formatMoney(props.item.donationValue) }}</td>
                <td>{{ formatter.formatDate(props.item.donationDate) }}</td>
                <td>{{ props.item.projectName }}</td>
                <td>{{ formatter.formatDate(props.item.creationDate) }}</td>
                <td>
                  <button
                    class="btn-line small mr-2"
                    title="Baixar Recibo"
                    @click="generateReceiptManual(props.item.id)"
                  >
                    <i class="fal fa-file" :style="getStyleStatus(props.item)"></i>
                  </button>
                </td>
                <td >
                  <v-checkbox @change="addToSubscriptionList(props.item)" v-if="props.item.isSigned != 1 && !props.item.isCanceled"></v-checkbox>
                </td>
                <td >
                  {{getReceiptStatus(props.item)}}
                </td>
                <td >
                  {{getSignerName(props.item)}}
                </td>
                <td>
                  <v-btn v-if="canCancelReceipt(props.item)" @click="cancelReceipt(props.item)">
                    Cancelar
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-layout>
    </div>

    <Modal
      v-model="dialog"
      modalTitle="Recibo Manual"
      :cardTitle="true"
      :withClose="true"
      myMaxWidth="640px"
      style="max-height: 70%"
      @input="closeDialog"
      :key="manualReceiptKey"
    >
      <v-container fluid pa-0>
        <v-layout pa-0>
          <v-flex list-scroll sm12 style="height: 82vh">
            <div class="content-text-block font-sys">
              <body class="d-flex flex-column" style="background-color: white">
                <div style="width: 100%">
                  <v-combobox
                    v-if="!isEmit"
                    ref="documentInput"
                    textLabel="CPF/CNPJ"
                    placeHolder="Adicione um novo CPF / CNPJ ou procure pelo nome ou documento"
                    v-model="personSelected"
                    :items="personsComboboxList"
                    :filter="customFilter"
                    item-text="text"
                    item-value="id"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    :filter-keys="['notFormated', 'text']"
                    @input.native="setDocument"
                    @change="confirmPerson"
                  >
                    <template v-slot:no-data>
                      <p class="ml-3 mt-3"
                        style="cursor: pointer;"
                        @click="addNewPerson"
                      >Cadastrar novo CPF/CNPJ</p>
                    </template>
                  </v-combobox>
                  <InputText
                    v-if="isEmit"
                    :disabled="true"
                    ref="holderNameInput"
                    type="text"
                    textLabel="CPF/CNPJ"
                    placeHolder="CPF/CNPJ"
                    v-model="receipt.document"
                    invalidInputMessage="Preencha o CPF/CNPJ"
                    :mask="['###.###.###-##', '##.###.###/####-##']"
                  />
                  <InputSelect
                    ref="stateInput"
                    textLabel="Projetos"
                    :items="projects"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    v-model="receipt.projectId"
                    valueAttribute="id"
                    textAttribute="fullName"
                  ></InputSelect>
                  <InputSelect
                    ref="operationTypeInput"
                    textLabel="Tipo de operação"
                    :items="['Doação', 'Patrocínio']"
                    :required="true"
                    invalidInputMessage="Por favor, preencha esse campo"
                    v-model="receipt.operationType"
                  ></InputSelect>
                  <InputMoney
                    ref="donationValueInput"
                    textLabel="Valor do incentivo"
                    v-model="receipt.donationValue"
                    :required="true"
                    :precision="2"
                    invalidInputMessage="Preencha o valor do incentivo"
                  />
                  <InputDate
                    type="text"
                    textLabel="Data do incentivo"
                    placeHolder="Data do incentivo"
                    v-model="receipt.donationDate"
                  />
                  <InputText
                    v-if="institution.salesForceIntegrationId > 0 && personSelected != null && personSelected.cnpj != null"
                    :required="true"
                    ref="salesForceOportunityIdInput"
                    type="text"
                    textLabel="Id da Oportunidade no SalesForce"
                    placeHolder="Id Oportunidade"
                    v-model="receipt.salesForceOportunityId"
                    invalidInputMessage="Preencha o Id da Oportunidade"
                  />
                  <div>
                    <template v-if="isEmit">
                    <big><strong>Selecione até 2 assinantes</strong></big>
                      <ul v-if="personSignReceiptList.length > 0">
                        <li v-for="person in personSignReceiptList" :key="person.id">
                          <input type="checkbox"  @input="setPersonSignReceiptSelected(person.personId)" :disabled="personSignReceiptSelected.length == 2"/>
                          <label style="padding-left:2%;">{{person.name}}</label>
                        </li>
                      </ul>
                      <ul v-else>
                        <li>Nem um assinante cadastrado para essa instituição.</li>
                      </ul>
                    </template>
                  </div>
                  <v-btn @click="emitReceiptByReceiptManual" v-show="isEmit && personSignReceiptSelected.length >= 1 && receipt.donationDate">Emitir</v-btn>
                  <v-btn @click="saveReceiptManual" v-if="!isEmit">Criar</v-btn>
                </div>
              </body>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </Modal>
    <ListPersonModal/>
    <NewPersonModal/>
    <!-- Modal Filters -->
    <AucAlert
      ref="confirmDate"
      title="Por favor, confirme a data do recibo para emissão"
      confirmButtonText="Ok"
      @confirm="generateReceiptByReceiptManual"
    />
  </div>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import HeroInner from "@/components/HeroInner.vue";
import DocService from "@/scripts/services/doc.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import CommonHelper from "../../../scripts/helpers/common.helper";
import File from "@/scripts/models/file.model";
import Modal from "@/components/Modal.vue";
import { IncentiveLaws } from "@/scripts/models/enums/incentiveLaws.enum.js";
import LocationService from "@/scripts/services/location.service.js";
import PersonService from "@/scripts/services/person.service.js";
import ReportService from "@/scripts/services/report.service.js";
import InstitutionService from "@/scripts/services/institution.service.js";
import InputSelect from '@/components/input/InputSelect.vue';
import NewPersonModal from "../ManualReceipt/modal/NewPersonModal.vue";
import ListPersonModal from "../ManualReceipt/modal/ListPersonModal.vue";
import { mapActions, mapGetters } from "vuex";

// Components
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";

export default {
  extends: ValidatableComponent,
  components: {
    AucAlert,
    Modal,
    InputSelect,
    NewPersonModal,
    ListPersonModal,
  },
  data() {
    return {
      verifyScroll: false,
      docService: new DocService(),
      formatter: new FormatterHelper(),
      locationService: new LocationService(),
      personService: new PersonService(),
      reportService: new ReportService(),
      institutionService: new InstitutionService(),
      commonHelper: CommonHelper,
      incentiveLaws: IncentiveLaws,
      tabSignature: 1,
      withoutPresident: false,
      president: { name: null, documentNumber: null },
      representative: { name: null, documentNumber: null },
      routes: Routes,
      receipsGenerating: [],
      yearSelected: 2020,
      file: new File(),
      receiptToUpload: null,
      entityId: 0,
      dialog: false,
      selected: [],
      headers: [
        {
          text: "ID",
          value: "receiptSequenceId",
          align: "left",
          width: "300px"
        },
        {
          text: "Nome/Razão Social",
          value: "donator",
          align: "left",
        },
        {
          text: "CPF/CNPJ",
          value: "document",
          align: "center",
        },
        {
          text: "Tipo de Pessoa",
          value: "document",
          align: "center",
        },
        {
          text: "Valor",
          value: "donationValue",
          align: "left",
        },
        {
          text: "Data do Recibo",
          value: "donationDate",
          align: "left",
        },
        {
          text: "Nome do projeto",
          value: "projectId",
          align: "left",
        },
        {
          text: "Criação",
          value: "creationDate",
          align: "left",
        },
        {
          text: "Baixar Recibo",
          value: "downloadReceipt",
          align: "left",
        },
        {
          text: "Selecionar Recibo",
          value: "selectReceipt",
          align: "left",
        },
        {
          text: "Status",
          value: "statusReceipt",
          align: "left",
        },
        {
          text: "Assinante",
          value: "personsWhoSigneThisReceipt",
          align: "left",
        },
      ],
      search: "",
      subscriptionList: [],
      signers: [],
      pagination: { 
        "descending": true, 
        "page": 1, 
        "rowsPerPage": 25, 
        "sortBy": "id", 
        "totalItems": 0 },
      isEmit: false,
      personSignReceiptList: [],
      personSignReceiptSelected:[],
      receiptsData: [],
      initialDate: null,
      finalDate: null,
      filterStatus: [],
      filterPerson: [],
      filterProject: [],
      itemFilterPerson: null,
      itemFilterStatus: null,
      itemFilterProject: null,
      dialogPerson: false,
      dialogListPerson: false,
      personToEdit: {},
      personList: null,
      documentToAdd: null,
      manualReceiptKey: 0,
      personModal: 0,
      personSelected: null,
      institution: { salesForceIntegrationId: 0 },
      receipt: {
        donationValue: 0,
        donationDate: null,
        projectId: null,
        campaignId: null,
      },
    };
  },
  async created() {
    this.entityId = this.$route.query.entityId;
    this.InstitutionId = this.$route.query.institutionId;

    this.docService
      .getSignersByEntityId(this.$route.query.entityId)
      .then((res) => {
        this.signers = res;
      });

    this.institutionService.find((data) => { this.institution = data }, this.InstitutionId)

    this.loadProjects()
    this.loadManualReceipts()
    this.personService.listPersonSignReceiptByEntity(this.entityId).then(data => {
      if (data) {
        this.personSignReceiptList = data;
      }
    }),
    this.loadPersonsCombobox();
    this.closePersonDialog();
    this.closeListDialog();
  },
  computed: {
    ...mapGetters([
      'personDocument',
      'personsComboboxList',
      'selectedPerson',
      'projects',
      'isFromReceipt',
      'personAdded',
      'receipts',
      'receiptsBackup',
      'projectsList',
      'userProfile',
    ]),
  },
  watch: {
    personAdded(val) {
      if (val && this.isFromReceipt) {
        this.personSelected = val;
      }
    }
  },
  methods: {
    ...mapActions([
      'setIsPersonConfirm',
      'setIsFromReceipt',
      'setIsEdit',
      'setPersonToEdit',
      'openPersonDialog',
      'closePersonDialog',
      'openListDialog',
      'closeListDialog',
      'reloadPersonsList',
      'setPersonDocument',
      'loadPersonsCombobox',
      'setSelectedPerson',
      'loadProjects',
      'loadManualReceipts',
      'setReceipts',
      'clearPerson'
    ]),
    resetReceiptModal() {
      this.receipt = {
        donationValue: 0,
        donationDate: null,
        projectId: null,
        campaignId: null
      }
      this.personSelected = null;
    },
    newPerson() {
      this.setIsFromReceipt(false);
      this.reloadPersonsList();
      this.openListDialog();
    },
    valid() {
      return this.isValid()      
    },
    customFilter(item, queryText, itemText) {
      const itemsToSearch = itemText.split(' - ')
      const rawDocument = itemsToSearch[0]
      const rawLowerCaseName = (itemsToSearch[1]).toLowerCase()

      return ((this.comboboxDocumentFilterCondition(queryText, rawDocument)) || this.comboboxNameFilterCondition(queryText, rawLowerCaseName))
    },
    comboboxDocumentFilterCondition(queryText, rawDocument) {
      const cleanDocument = rawDocument.replaceAll(".","").replaceAll("/","").replaceAll("-","")
      return ((rawDocument.indexOf(queryText) > -1)  || (cleanDocument.indexOf(queryText) > -1))   
    },
    comboboxNameFilterCondition(queryText, rawLowerCaseName) {
      const cleanLowerCaseName = rawLowerCaseName.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return ((rawLowerCaseName.indexOf(queryText) > -1) || (cleanLowerCaseName.indexOf(queryText) > -1))
    },
    getSignerName(receipt){
      if (receipt.personsWhoSigneThisReceipt) {
        var names = "";

        receipt.personsWhoSigneThisReceipt.forEach(person => {
          if (names) {
            names += ", " + person.name;
          }
          else{
            names += person.name;
          }
        });

        return names;
      }
    },
    addNewPerson() {
      if (CommonHelper.validateCPF(this.personDocument) || CommonHelper.validateCNPJ(this.personDocument)) {
        this.setIsFromReceipt(true)
        this.closeListDialog();
        this.openPersonDialog();
        this.setIsEdit(false)
        this.manualReceiptKey += 1;
      } else {
        CommonHelper.swal('CPF/CNPJ inválido, digite um CPF ou CNPJ válido para continuar')
      }
    },
    setDocument(e) {
      let value = e.target.value
      this.setPersonDocument(value)
    },
    confirmPerson() {
      if (this.personSelected) {
        this.setSelectedPerson(this.personSelected)
        this.setIsPersonConfirm(true)
        this.setIsEdit(true)
        this.setPersonToEdit(this.personSelected)
        this.openPersonDialog()
      }
    },
    removeNotIncentivedReceipts(receipts) {
      return receipts.filter((r) => {
        if (r.projectName)
        return (r.projectName).toLowerCase() !== 'não incentivado'
      })
    },
    editPerson() {
      this.setIsEdit(true)
      this.personToEdit = this.personSelected;
      this.openPersonDialog();
      this.closeListDialog();
    },
    async createExcelReport(receipts) {
      this.receiptsData = [];
        for (const receipt of receipts) {
          const personType = this.getPessoaFisicaJuridica(receipt.document) === 'PJ' ? 'Pessoa Jurídica' : 'Pessoa Física'
          this.receiptsData.push({
            receiptId: receipt.receiptSequenceId ? receipt.receiptSequenceId : 0,
            name: receipt.donator,
            cpfCnpj: this.getFormatCPForCNPJ(receipt.document),
            personType: personType,
            donationValue: (receipt.donationValue).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            donationDate: this.dateFormat(receipt.donationDate),
            projectName: receipt.projectName,
            creationDate: this.dateFormat(receipt.creationDate),
            status: this.getReceiptStatus(receipt),
            subscriber: receipt.personsWhoSigneThisReceipt ? receipt.personsWhoSigneThisReceipt[0].name : 'Não assinado'
          })
        }
      await this.requestManualSignatureReport(this.receiptsData)
    },
    cancelReceipt(receipt){
      this.docService.cancelReceipt(receipt).then(data => {
        if (data) {
          this.$router.go();
        }
      })
    },
    canCancelReceipt(receipt){
      return ((receipt.isSigned != 2) && receipt.isCanceled == false);
    },
    setPersonSignReceiptSelected(personId){
      if (this.personSignReceiptSelected.includes(personId)) {
        var index = this.personSignReceiptSelected.indexOf(personId);
        this.personSignReceiptSelected.splice(index, 1);

        return false;
      }
      this.personSignReceiptSelected.push(personId);
      
      if (this.personSignReceiptSelected.length > 2) {
        this.personSignReceiptSelected.shift();
      }

      return true;
    },
    getPessoaFisicaJuridica(document){
      if (document.length > 11) {
        return "PJ";
      }
      return "PF";
    },
    getReceiptStatus(receipt){
      if (receipt.isCanceled) {
        return "Cancelado";
      }
      if (receipt.isSigned == 1) {
        return "Assinado";
      }
      if (receipt.isSigned == 0) {
        return "Pronto para assinar"
      }
      return "Pronto para emitir";
    },
    getStyleStatus(receiptManual){
      if (receiptManual.isSigned == 1) {
        return "color: #02acb4";
      }
    },
    generateReceiptByReceiptManual(){
      this.subscriptionList.forEach((element) => {
            this.receipt = element;
            this.dialog = true;
            this.isEmit = true;
      });
    },
    emitReceiptByReceiptManual(){
      if (this.receipt.isSigned == 2) {
        this.receipt.personSignReceiptSelected = this.personSignReceiptSelected;
        this.docService.setDonationDateToReceiptManual(this.receipt).then(data => {
          if (data) {
            this.docService.generateReceiptByReceiptManual([this.receipt.id]).then(data => {
              if (data) {
                this.commonHelper.swal(data, null, "success");
                this.dialog = false;
                this.isEmit = false;
                this.loadManualReceipts();
              }
            })
          }
        }) 
      }else{
        this.commonHelper.swal('Selecione um recibo com o status "Pronto para Emitir"', null, "error");
        this.loadManualReceipts()
      }
    },
    certisignRequest() {
      var url = process.env.VUE_APP_API_CERTISIGN;
      var signeds = [];
      var docs = [];
      var receiptManualIds = [];
      const self = this;
      var confirmConfig = {
        textOkButton: "Sim",
        textCancelButton: "Não",
        textMessage:
          "Interromper o processo de assinatura causará inconsistência nos status das assinaturas já efetuadas. No entanto, da próxima vez que tentar assinar os status serão atualizados. Deseja realmente interromper o processo de assinatura em lote?",
      };
      this.addCertisignCSS()

      this.subscriptionList.forEach((element) => {
        receiptManualIds.push(element.id);

      });

      this.docService
        .getReceiptByReceiptManualId(receiptManualIds)
        .then((data) => {
          if (data && data.length > 0) {
            docs = data;
            
            this.docService.getManualSigners(receiptManualIds[0]).then(res => {
              if (res) {
                let signer = null;
                for (let index = 0; index < res.length; index++) {
                  const sig = res[index].replace(/\D/g, '');
                  if(sig == this.userProfile.cpfOrCNPJ)
                    signer = sig
                }
                if(signer == null)
                  signer = res[0]

                new window.CertisignIntegration(url, 560, 560, false, confirmConfig)
                .sign(docs, signer)
                .onSignCompleted(function (signStatus) {
                  if (Array.isArray(signStatus)) {
                    for (var i = 0; i < signStatus.length; i++) {
                      var result = signStatus[i];
                      if (result == 0 || result == 1) {
                        signeds.push(docs[i]);
                      }
                    }
                  } else {
                    if (signStatus == 0 || signStatus == 1) {
                      signeds.push(docs[0]);
                    }
                  }
                  if (signeds.length > 0) {
                    self.saveSigned(signeds);
                  }
                });
              }
            });
          }
        });
    },
    addCertisignCSS(){
      var link = document.createElement( "link" );
      link.href = "https://portaldeassinaturas.com.br/Content/Integration/certisign-client-integration-min.css?v=2";
      link.type = "text/css";
      link.rel = "stylesheet";
      link.media = "screen,print";
      link.id = "css-certisign"
      document.getElementsByTagName( "head" )[0].appendChild( link );
    },
    saveSigned(docs) {
      this.docService.saveReceiptSigned(this.callbackSaveReceipts, docs);
    },
    callbackSaveReceipts(data) {
      setTimeout(() => {
                  this.$router.go();
                }, 2300);
    },
    addToSubscriptionList(receipt) {
      if (this.subscriptionList.find((item) => item.id == receipt.id)) {
        this.subscriptionList.pop(receipt);
      } else {
        this.subscriptionList.push(receipt);
      }
    },
    async getProjectName(projectId) {
      if (this.projects.length > 0 && projectId != 0) {
        var projectName = this.projects.find(
          (project) => project.id == projectId
        );
        if(projectName)
          return projectName.shortName;
      }
      return 'Não incentivado';
    },
    saveReceiptManual() {  
      if(this.personSelected == null || typeof this.personSelected !== 'object') {
        CommonHelper.swal("CPF / CNPJ não selecionado")
        return
      }
      if (this.valid()) {
        CommonHelper.showLoader()    
        this.receipt.institutionId = this.$route.query.institutionId;
        if (!this.receipt.donationDate) {
          this.receipt.donationDate = null;
        }

        const payload = {
          ...this.receipt,
          ...this.selectedPerson,
          id: 0
        }

        delete this.receipt.text;
        delete this.receipt.notFormated
        this.receipt.id = 0;
        
        this.docService.saveReceiptManual(payload).then(() => {
            //this.$router.go()            
            this.loadManualReceipts();
            this.resetReceiptModal();
            this.closeDialog();
          }).finally(() => {
            CommonHelper.hideLoader()
          });    
        }
      
    },
    generateReceiptManual(receiptManualId) {
      this.docService.generateReceiptManual(receiptManualId).then((res) => {
        var sampleArr = this.base64ToArrayBuffer(res);
        this.saveByteArray("Recibo_Manual_" + receiptManualId, sampleArr);
      });
    },
    base64ToArrayBuffer(base64) {
      var binaryString = window.atob(base64);
      var binaryLen = binaryString.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },
    saveByteArray(reportName, byte) {
      var blob = new Blob([byte], { type: "application/pdf" });
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      var fileName = reportName;
      link.download = fileName;
      link.click();
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    getFormatCPForCNPJ(document) {
      if (document.length <= 11) {
        return this.formatter.formatCpf(document);
      } else {
        return this.formatter.formatCnpj(document);
      }
    },
    async requestManualSignatureReport(receipts) {
      await this.reportService.manualSignatureReport(receipts).then((response) => {
        this.openBlob(response, "Relatorio_RecibosManuais", "application/excel");
      })
    },
    openBlob(response, name, type) {
      if (response == null) return;
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: type })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        name + "_" + this.$moment().format("YYYY-MM-DD_H:m") + ".xlsx"
      );
      document.body.appendChild(link);
      link.click();
    },
    async filterItems(type, value) {
        this.setReceipts(this.receiptsBackup)
        let valueFormated = '0'
        if (type !== 'date') valueFormated = value.toString().toLowerCase()

        // check if filter exist in the array filters, if exists change the value for the new one
        if (type === 'status' && this.filterStatus.length === 0) this.filterStatus.push(valueFormated)
        if (type === 'status' && this.filterStatus.indexOf(valueFormated) === -1) {
          this.filterStatus = []
          this.filterStatus.push(valueFormated)
        }
        if (type === 'person' && this.filterPerson.length === 0) this.filterPerson.push(valueFormated)
        if (type === 'person' && this.filterPerson.indexOf(valueFormated) === -1) {
          this.filterPerson = []
          this.filterPerson.push(valueFormated)
        }
        if (type === 'project' && this.filterProject.length === 0) this.filterProject.push(valueFormated)
        if (type === 'project' && this.filterProject.indexOf(valueFormated) === -1) {
          this.filterProject = []
          this.filterProject.push(valueFormated)
        }
        
        // filter items
          if (this.filterStatus.length !== 0 && this.filterStatus[0] !== 'todos' && this.filterStatus[0] !== '0') {
            this.setReceipts(this.receipts.filter((y) => this.getReceiptStatus(y).toLowerCase() === this.filterStatus[0]))
          }
          if (this.filterProject.length !== 0 && this.filterProject[0] !== 'todos' && this.filterStatus[0] !== '0') {
            this.setReceipts(this.receipts.filter((p) => p.projectName.toLowerCase() === this.filterProject[0]))
          }
          if (this.filterPerson.length !== 0 && this.filterPerson[0] !== 'todos' && this.filterStatus[0] !== '0') {
            this.setReceipts(this.receipts.filter((y) => {
              let personType = this.getPessoaFisicaJuridica(y.document).toLowerCase() === 'pj' ? 'pessoa jurídica' : 'pessoa física'
              return personType.toLowerCase() === this.filterPerson[0]
            }))
          }
          if (this.initialDate && this.finalDate) {
            this.setReceipts(this.receipts.filter((x) => {
              return (new Date(x.donationDate) >= this.parseTime(this.initialDate)) && (new Date(x.donationDate) <= this.parseTime(this.finalDate))
            }))
          }

        // filter by date
        if (type === 'date' && this.initialDate && this.finalDate) {
            this.setReceipts(this.receipts.filter((x) => {
              return (new Date(x.donationDate) >= this.parseTime(this.initialDate)) && (new Date(x.donationDate) <= this.parseTime(this.finalDate))
          }))
        }
      },
    clearDate() {
      this.initialDate = ''
      this.finalDate = ''
      document.getElementById('initialDate').value = ''
      document.getElementById('finalDate').value = ''
      this.filterItems('date', null)
    },
    closeDialog() {
      this.dialog = false;
      this.manualReceiptKey += 1;
    },
    parseTime(str) {
      let d = str.substr(0,2);
      let m = str.substr(2,2);
      let y = str.substr(4,4);
      return new Date(m+'/'+d+'/'+y);
    },
    dateFormat(date) {
      const d = new Date(date)
      const day = (d.getDate()).toString().padStart(2, '0')
      const month = (d.getMonth() + 1).toString().padStart(2, '0')
      return day + '/' + month + '/' + d.getFullYear()
    }
  },
};
</script>
